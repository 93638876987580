import {required, requiredIf} from "@vuelidate/validators"
import {useVuelidate} from '@vuelidate/core'
import FormWrapper from "@/components/form-wrapper/form-wrapper.vue"
import GetterWrapper from "@/components/getter-wrapper/getter-wrapper.vue"
import InputWrapper from "@/components/input-wrapper/input-wrapper.vue"
import ViewDetails from "@/components/view-details/view-details.vue"
import ViewHeader from "@/components/view-header/view-header.vue"
import {acceptTerms, digitOnly} from "@/helpers/vuelidate"
import {getCurrencySymbol} from "@/helpers/i18n"

import {activePLasticCard, changeStatusCard, getCard, postFundStripe} from '@/store/api'
import ViewBlocs from "@/components/view-blocs/view-blocs.vue";
import router from "@/router";
import Spinner from "@/components/spinner/spinner.vue";

export default {
  name: "Activate",
  components: {
    Spinner,
    ViewBlocs,
    FormWrapper,
    GetterWrapper,
    InputWrapper,
    ViewDetails,
    ViewHeader,
  },
  props: {},
  data() {
    return {
      v$: useVuelidate(),
      tos: [],
      apiLoad: [
        {
          api: getCard,
          data: {id: this.$route.params.id}
        }
      ],
      getCurrencySymbol,
      card: {},
      loading: false,
      form: {
        terms: false,
        cvv_0: '',
        cvv_1: '',
        cvv_2: '',
      },
      error: null,
      invalidNums: [false, false, false, false],
      store: this.$store.state,
    }
  },
  computed: {
    currentBalance() {
      if (this.card.amount && this.form.amount) {
        const balance = this.card.amount - parseFloat(this.form.amount)
        if (balance > 0) {
          return balance
        }
        return 0
      }
      return 0
    },
  },
  emits: ['submitted', 'error'],
  validations: {
    form: {
      terms: {
        required,
        acceptTerms: acceptTerms()
      },
      cvv_0: {
        required: requiredIf(function () {
          return this.isPlastic()
        }), digitOnly: digitOnly()
      },
      cvv_1: {
        required: requiredIf(function () {
          return this.isPlastic()
        }), digitOnly: digitOnly()
      },
      cvv_2: {
        required: requiredIf(function () {
          return this.isPlastic()
        }), digitOnly: digitOnly()
      },
    }
  },
  created: function () {
  },
  mounted: function () { /* DOM accessible via this.$el */
  },
  beforeUnmount: function () { /* clean all non-Vue listeners/objects */
  },
  methods: {
    onData([data]) {
      this.card = data
      this.tos = JSON.parse(this.card.program.tos)[this.$i18n.locale]
    },
    async onSubmitted() {
      console.log('on submitted')
      this.loading = true
      try {
        if (this.isPlastic()) {
          await this.execPlasticCard()
        } else {
          await this.acceptCard()
        }
        this.loading = false
        await router.push({
          name: 'card-item',
          params: {
            id: this.card.id,
            status: 'ACCEPTED'
          }
        })
      } catch (e) {
        this.loading = false
        console.error(e)
      }
    },
    async acceptCard() {
      await changeStatusCard({
        id: this.card.id,
        status: 'ACCEPTED'
      })
    }
    ,
    async execPlasticCard() {
      try {

        let data = {
          id: this.card.id,
          cvv: this.form.cvv_0 + "" + this.form.cvv_1 + "" + this.form.cvv_2
        }
        await activePLasticCard(data)
        this.error = null
        this.$emit('submitted')
      } catch (error) {
        if (error.code === 'ERR_BAD_REQUEST') {
          this.error = 'card.activate.error.cvv'
        }
        this.$emit('error')
        this.loading = false
        throw error
      } finally {
        this.loading = false
      }
    }
    ,
    onError(error) {
      console.error('[ERROR]', error)
    }
    ,
    onCancel() {
      router.push({
        name: 'card-item',
        params: {id: this.card.id}
      })
    },
    isPlastic() {
      return this.card.plastic
    },
    updateCvv(evt, ref) {
      this.form['cvv' + ref] = parseInt(evt.target.value)

      this.invalidNums[0] = !!(this.form.cvv_0.toString().length && isNaN(this.form.cvv_0))
      this.invalidNums[1] = !!(this.form.cvv_1.toString().length && isNaN(this.form.cvv_1))
      this.invalidNums[2] = !!(this.form.cvv_2.toString().length && isNaN(this.form.cvv_2))

      if (ref === 2)
        return
      const nextInput = this.$refs['cvv_' + (ref + 1)]
      nextInput.focus()
    },
  }
}
